@media (max-width: 1500px) {
}

@media (max-width: 1200px) {
  .acc-form .nav {
    column-gap: 0;
    row-gap: 15px;
  }

  .listView .productCard {
    padding: 20px 10px;
  }

  .listView .productCard .p-data .form-group {
    width: 49% !important;
    order: 0 !important;
  }

  .listView .productCard .p-data .form-group:last-child {
    width: 100% !important;
  }

  .content-wrap {
    /* width: 100%; */
  }
}

@media (max-width: 1024px) {
  .main-sidebar {
    transform: translateX(150%);
    transition: all 0.3s;
  }

  .main-head .logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: #0b1e2b;

    gap: 7px;
    .authWrap,
    .darkSwitcher {
      display: block;
      & .au-data * {
        color: white;
      }
    }
    .darkSwitcher {
      filter: brightness(0) invert(1);
    }
    .dropdown-toggle {
      padding: 0 !important;
    }
  }

  .main-head .logo .btn {
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    font-size: 13px;
    padding: 0 20px;
    color: white;
  }

  .burgerBtn .btn {
    display: none;
  }

  .head-wrp {
    padding: 18px;
  }

  .burgerBtn .toggleSidebar {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  .burgerBtn {
    margin: 0;
  }

  .content-wrap {
    width: 100%;
    /* margin: 137px auto; */
    margin: 71px auto;
    padding: 28px;
  }

  /* ***** Sidebar **** */
  .sidebarToggled .content-wrap {
    /* margin: 167px auto; */
    width: 100%;
    padding: 15px;
  }

  .sidebarToggled .main-head .head-wrp {
    padding: 18px;
  }

  .sidebarToggled .main-sidebar {
    transform: none;
    width: 292px;
  }

  .sidebarToggled .main-sidebar .logo .logo-info,
  .sidebarToggled .main-sidebar .side-nav ul li a span,
  .sidebarToggled .main-sidebar .side-actions a span {
    opacity: 1;
    position: relative;
  }

  .sidebarToggled .main-sidebar .side-nav ul li a,
  .sidebarToggled .main-sidebar .side-actions a,
  .sidebarToggled .main-sidebar .side-actions {
    text-align: initial;
    justify-content: normal;
  }

  .sidebarToggled .main-sidebar .side-nav ul li a svg,
  .sidebarToggled .main-sidebar .side-actions a svg {
    margin: 0 0 0 13px;
  }

  .overlay-s.fire {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
  }

  .filter-wrp {
    margin: 0;
    padding: 30px 0 10px;
    width: 100%;
  }

  .main-sidebar .logo {
    justify-content: center;
    padding: 20px;
  }

  .timeWrap span {
    width: 46px;
    height: 46px;
    line-height: 46px;
    font-size: 15px;
  }

  .searchWrap .form-group {
    margin: 0;
  }

  .offScroll {
    overflow: hidden;
  }

  .switcher .knobs:before {
    transition: all 0.3s;
  }

  .stores-selection .dropdown-menu {
    left: 0 !important;
    right: auto !important;
  }

  .table-wrap .card-body header + div {
    overflow: auto hidden !important;
  }

  .thumbs .thumb {
    width: 95px;
    height: 95px;
  }

  .thumbs .thumb .btn-remove {
    width: 20px;
    height: 20px;
    padding: 2px;
  }

  .sidebarToggled .main-sidebar .side-nav > ul {
    overflow: hidden;
    overflow-y: auto;
    padding-inline: 10px 15px;
  }

  /* .sidebarToggled .main-sidebar .side-nav ul li a ~ .children {
    max-height: 400px;
  } */

  .sidebarToggled .main-sidebar .stores-selection .dropdown-toggle {
    grid-column-gap: 10px;
    column-gap: 10px;
    min-width: 205px;
    font-size: 13px;
    width: 100%;
    justify-content: flex-start;
  }

  .stores-selection .dropdown-menu {
    right: 0 !important;
    left: auto !important;
  }

  .sidebarToggled .main-sidebar .side-nav ul li a,
  .sidebarToggled .main-sidebar .side-actions a {
    border-radius: 3px;
  }

  .sidebarToggled .main-sidebar .side-nav {
    padding: 0 10px;
  }

  .main-sidebar .side-nav ul li a.active {
    padding: 9px 12px;
  }

  .logo .logo-info {
    filter: none;
  }

  .sidebarToggled .main-sidebar .logo .logo-info {
    filter: brightness(0) invert(1);
  }

  .orders-list li .order-data > div {
    flex-wrap: wrap;
    row-gap: 5px;
  }

  .orders-list li .order-img {
    width: 50px;
    height: 50px;
    min-width: 50px;
  }

  .orders-list li {
    padding: 15px 0;
    align-items: flex-start;
  }

  .main-head .stores-selection {
    display: block;
  }

  .main-head .stores-selection .dropdown-menu {
    left: 50% !important;
    right: auto !important;
    top: 60px !important;
    transform: translateX(-50%) !important;
    width: 90%;
  }

  .main-head .stores-selection .dropdown {
    position: unset;
  }

  .head-wrp {
    position: relative;
  }

  .main-sidebar .stores-selection {
    display: none;
  }

  .main-sidebar .logo {
    margin-bottom: 20px;
  }

  .main-sidebar .side-nav {
    height: calc(100% - 110px);
  }

  .head-wrp .burgerBtn {
    display: none;
  }

  .searchWrap .form-group input.form-control {
    width: 250px;
  }

  .breadcrumb {
    margin: 20px 0;
  }

  .no-store {
    padding: 20px 0;
  }

  .no-store .preview {
    height: auto;
  }

  .no-store .preview img {
    width: auto;
    height: auto;
    object-fit: unset;
  }
}

@media (max-width: 768px) {
  .authWrap .au-data {
    display: none;
  }

  .authWrap .dropdown {
    min-width: 35px;
  }

  .authWrap .dropdown-toggle svg {
    display: none;
  }

  .noificationWrap .menu-item-has-children .dropdown-toggle span,
  .noificationWrap .menu-item-has-children > span {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  .badgo {
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 11px;
    top: -8px;
    right: -8px;
  }

  .noificationWrap .btn {
    overflow: visible;
  }

  .noificationWrap .menu-item-has-children .dropdown-toggle span svg {
    width: 19px;
  }

  .authWrap .dropdown-menu {
    min-width: 160px;
  }

  .stores-selection .dropdown-toggle {
    min-width: 180px;
  }

  .searchWrap .form-group {
    min-width: 300px;
  }

  .searchWrap .form-group select.form-control {
    width: 80px;
  }

  .head-wrp {
    column-gap: 20px;
  }

  .searchWrap .form-group input.form-control {
    width: 150px;
    font-size: 12px;
  }

  .searchWrap .form-group select.form-control {
    min-width: auto;
    padding: 0 15px;
    font-size: 12px;
  }

  .ordersFilterSidebar {
    width: 100%;
  }

  .order-btns,
  .orderLayout {
    display: none;
  }

  .modal-dialog {
    max-width: calc(100% - 40px);
  }

  .productsTable td {
    min-width: 136px;
    font-size: 12px;
    padding: 15px;
  }

  .productsTable {
    overflow: hidden;
    overflow-x: auto;
  }

  .productsTable table {
    table-layout: auto;
  }

  .productsTable td:first-child {
    min-width: 250px;
    max-width: 250px;
  }

  .productsTable td strong {
    font-size: inherit;
  }

  .productsTable .product a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
  }

  .productsTable .product img {
    min-width: 60px;
  }

  .productsTable .product {
    column-gap: 15px;
  }
}

@media (max-width: 625px) {
  .content-wrap {
    padding: 15px;
  }

  .thumbs .thumb {
    width: 91px;
    height: 91px;
  }

  .thumbs {
    column-gap: 15px;
    row-gap: 15px;
  }

  .chart-body.pieChart .recharts-wrapper > svg {
    width: 55%;
    float: left;
    height: 140px;
  }

  .chart-body.pieChart {
    height: 120px !important;
  }

  .customers-list .c-item .i-img {
    width: 75px;
    height: 75px;
    margin: 0 auto 12px;
  }

  .card-head h4,
  .reports-blocks .card h4 {
    font-size: 14px;
  }

  .card-head p,
  .customers-list .c-item .i-data p {
    font-size: 10px;
  }

  .customers-list .c-item .i-data h4,
  .project-item .i-head a {
    font-size: 12px;
  }

  .searchWrap .form-control {
    min-width: 140px;
    height: 38px;
    width: 140px;
  }

  .timeWrap span {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 11px;
    margin: 0 2px;
  }

  .searchWrap .form-group button {
    height: 29px;
    line-height: 29px;
    width: 29px;
  }

  .content-wrap {
    margin: 159px auto;
  }

  .filter-wrp .form-group .form-control {
    min-width: 100%;
  }

  .control-wrp .btn.btn-big,
  .control-wrp .card {
    height: 46px;
    line-height: 46px;
    /* margin-bottom: 30px; */
    font-size: 13px;
    padding: 0;
    h5 {
      margin-bottom: 0;
    }
  }

  .control-wrp .card {
    flex-wrap: wrap;
    justify-content: center;
  }

  .control-wrp .card h5 {
    margin-bottom: 15px;
  }

  .table-pager .pagination {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    display: block;
    white-space: nowrap;
  }

  .table-pager .pagination .page-item {
    display: inline-block;
  }

  input[type="checkbox"],
  input[type="radio"],
  input[type="checkbox"]:checked::before,
  input[type="radio"]:checked::before {
    width: 20px;
    height: 20px;
    border-radius: 3px !important;
  }

  input[type="checkbox"]:checked::before,
  input[type="radio"]:checked::before {
    line-height: 20px;
    font-size: 14px;
  }

  input[type="checkbox"]:checked::after,
  input[type="radio"]:checked::after {
    animation: click-wave1 0.65s;
  }

  @keyframes click-wave1 {
    0% {
      height: 20px;
      width: 20px;
      opacity: 0.35;
      position: relative;
    }

    100% {
      height: 30px;
      width: 30px;
      opacity: 0;
      margin: -6px;
    }
  }

  .rdt_TableCell div,
  .rdt_TableCol * {
    font-size: 13px;
  }

  .modal-dialog {
    min-height: 100% !important;
    max-width: 100% !important;
  }

  .productDataModal .modal-footer .btn {
    min-width: 120px;
  }

  .controllerHolder {
    max-width: 330px;
  }

  .modal-content {
    /* border-radius: 0; */
    width: 100%;
    max-height: 95%;
    /* position: absolute; */
    top: 0;
    right: 0;
    overflow: hidden;
    overflow-y: auto;
  }

  .modal-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px;
    overflow-x: auto !important;
  }

  .modal-wrp .modal-icon {
    width: 80px;
    height: 80px;
  }

  .modal-wrp h4 {
    font-size: 18px;
  }

  .modal-btns .btn {
    height: 38px;
    line-height: 38px;
    font-size: 15px;
    padding: 0 30px;
  }

  .content-wrap,
  .sidebarToggled .content-wrap {
    margin-top: 0;
  }

  .main-head {
    position: relative;
  }

  .card-inner .checker input[type="checkbox"]:after {
    animation: click-wave 0.65s;
  }

  .card-inner .checker input[type="checkbox"] {
    width: 26px;
    height: 26px;
  }

  .card-inner .checker input[type="checkbox"]::before {
    line-height: 26px;
    width: 26px;
    height: 26px;
    font-size: 17px;
  }

  .card-inner {
    min-height: 120px;
  }

  .images-wrp .img-holder {
    width: 95px;
    min-width: 95px;
  }

  .stores-selection .dropdown-menu {
    width: 335px;
    min-height: 165px;
  }

  .stores-selection .dropdown-menu .drop-scrollable::before,
  .stores-selection .dropdown-menu a:hover img,
  .searchWrap {
    display: none;
  }

  .stores-selection .dropdown-menu a {
    font-size: 14px;
    width: 50%;
  }

  .stores-selection .dropdown-menu a img {
    width: 110px;
    left: 15px;
  }

  .stores-selection .dropdown-menu {
    padding: 20px 0 0 0;
  }

  .drop-scrollable {
    padding: 0 15px;
  }

  .order-top .order-table {
    flex-wrap: wrap;
    padding: 0;
    column-gap: 0;
    row-gap: 0;
    border-radius: 0;
  }

  .order-top .order-table li {
    width: 50%;
    border: 1px solid #dfdfdf;
    padding: 15px;
  }

  .order-top .order-table li:last-child {
    width: 100%;
  }

  .order-top .orderslist ul li .order-img {
    width: 70px;
    height: 60px;
  }

  .order-top .orderslist ul li {
    column-gap: 15px;
  }

  .order-bottom h4 {
    font-size: 14px;
    padding: 15px;
  }

  .order-bottom h4 span {
    font-size: inherit;
  }

  .order-bottom ul {
    padding: 15px;
  }

  .noificationWrap .dropdown-menu {
    min-width: 300px;
    left: -30px !important;
    right: auto !important;
  }

  .stores-selection .dropdown .dropdown-toggle {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    min-width: 100px;
    white-space: nowrap;
    padding: 0 0 0 15px;
  }

  .stores-selection .dropdown a {
    color: #000;
  }

  .stores-selection .dropdown span {
    min-width: 40px;
    overflow: visible;
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }

  .stores-selection .dropdown span .rippon {
    width: 34px;
    height: 20px;
    top: -5px;
  }

  .stores-selection .dropdown span i {
    font-size: 8px;
  }

  .head-wrp .left-actions {
    column-gap: 3px;
  }

  .darkSwitcher {
    margin: 0;
  }

  .searchToggle {
    display: inline-block;
  }

  .searchWrap {
    position: absolute;
    left: 0;
    top: 83%;
    padding: 10px;
    background-color: #fff;
    display: block;
    width: 100%;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
  }

  .searchWrap .form-control {
    width: 100%;
  }

  .searchWrap.active {
    opacity: 1;
    visibility: visible;
    top: 100%;
  }

  .searchWrap .form-group {
    min-width: 100%;
  }

  .searchWrap .form-group input.form-control {
    width: 200px;
  }

  .ordersActions {
    column-gap: 15px;
  }

  .ordersActions a.btn {
    width: 100%;
  }

  .ordersActions .ordersServices,
  .ordersActions .btn-black {
    width: 50%;
  }

  .settingsHead .card {
    flex-wrap: wrap;
    row-gap: 15px;
  }

  .settingsHead .nav {
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 10px;
  }

  .settingsHead .nav::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  .settingsHead .nav::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 15px;
  }

  .settingsHead .nav::-webkit-scrollbar-thumb {
    background-color: #128192;
    border-radius: 15px;
  }

  .settingsHead .nav .nav-link {
    white-space: nowrap;
    padding: 7px 20px;
    border-radius: 50px;
    font-size: 12px;
  }

  .card {
    padding: 15px;
  }

  .settingItem .card {
    padding: 15px;
    min-height: 143px;
  }

  .settingItem h4 {
    font-size: 12px;
  }

  .settingItem span {
    font-size: 10px;
    max-height: 31px;
    min-height: 31px;
  }

  .no-store .card {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    text-align: center;
    row-gap: 20px;
  }

  .no-store .card .s-img {
    width: 120px;
    height: 120px;
  }

  .reports-blocks .inner .i-icon {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 50px;
  }

  .reports-blocks .inner .i-icon img {
    width: 100%;
    height: 100%;
  }

  .reports-blocks .inner {
    column-gap: 0;
    width: 100%;
    text-align: right;
    justify-content: flex-end;
  }

  .ordersActions {
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }

  .ordersActions .ordersServices {
    width: 100%;
  }

  .ordersActions > .btn {
    width: auto !important;
    margin: -65px 0 0;
    font-size: 12px;
    min-width: 130px;
  }

  .ordersActions .ordersServices .dropdown {
    width: 50%;
  }

  .ordersActions .btn-black {
    justify-content: center;
    width: auto;
    height: 38px;
    min-width: 130px;
  }

  .ordersServices .dropdown-toggle {
    height: 38px;
    font-size: 12px;
  }

  .productDataModal .form-group .form-body .col-12 .marketCoupon div {
    flex-wrap: wrap;
  }

  .productDataModal .form-group .form-body .col-12 .marketCoupon::after,
  .productDataModal .form-group .form-body .col-12 .marketCoupon div::after {
    display: none;
  }

  .productDataModal .form-group .form-body .col-12 .marketCoupon h4 {
    width: 100%;
    font-size: 16px;
    font-family: "Bahij-semibold";
    margin-bottom: 8px;
  }

  .productDataModal .form-group .form-body .col-12 .marketCoupon p {
    font-size: 12px;
    font-family: "Bahij-light";
  }

  .productDataModal .form-group .form-body .col-12 .marketCoupon {
    margin-top: 30px;
    justify-content: space-between;
  }

  .productDataModal
    .form-group
    .form-body
    .col-12
    .marketCoupon
    .switcher
    .switcher-input:checked
    ~ .layer {
    background-color: #49ca6e;
  }

  .productDataModal
    .form-group
    .form-body
    .col-12
    .marketCoupon
    .switcher
    .layer,
  .productDataModal
    .form-group
    .form-body
    .col-12
    .marketCoupon
    .switcher
    .knobs {
    position: absolute;
  }

  .productDataModal .nav {
    width: 100%;
    order: 1;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: auto;
    margin-top: 15px;
    max-width: 100%;
    display: block;
    white-space: nowrap;
    padding-bottom: 10px;
  }

  .productDataModal .modal-header {
    flex-wrap: wrap;
  }

  .productDataModal .nav .btn {
    white-space: nowrap;
    padding: 0 15px;
  }

  .productDataModal .nav .nav-item {
    display: inline-block;
  }

  .productDataModal .nav::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .productDataModal .nav::-webkit-scrollbar-track {
    border-radius: 15px;
    background: #d5d5d5;
  }

  .productDataModal .nav::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background: #336df4;
  }

  .productDataModal
    .form-group
    .form-body
    .col-12
    .marketCoupon
    div:first-child {
    max-width: 70%;
  }

  .modal {
    overflow: hidden;
  }

  .orderTop ul {
    flex-wrap: wrap;
    row-gap: 15px;
  }

  .orderTop ul li {
    width: 100%;
    text-align: inherit;
  }

  .orderTop ul li span {
    justify-content: flex-start;
  }

  .orderBlocks .card {
    min-height: auto;
  }

  .historyInfo li .h-info {
    flex-wrap: wrap;
  }

  .historyInfo li .h-info span {
    width: 100%;
    margin-top: 7px;
    color: #000;
  }

  .productCard .p-img {
    height: auto;
  }

  .brandDataModal .form-group .form-body .multiUploadWrapper .form-group div {
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
  }

  .brandDataModal
    .form-group
    .form-body
    .multiUploadWrapper
    .form-group
    div
    label.form-uploader,
  .brandDataModal
    .form-group
    .form-body
    .multiUploadWrapper
    .form-group
    div
    .emptyPhotos,
  .brandDataModal
    .form-group
    .form-body
    .multiUploadWrapper
    .form-group
    div
    .thumbs {
    width: 48%;
    margin: 0;
  }

  .brandDataModal
    .form-group
    .form-body
    .multiUploadWrapper
    .form-group
    div
    label.form-uploader {
    min-height: 250px;
  }

  .brandDataModal
    .form-group
    .form-body
    .multiUploadWrapper
    .form-group
    div.thumb
    img {
    border-radius: 10px;
  }

  .brandDataModal .form-group {
    margin: 0;
    width: 100%;
  }

  .brandDataModal .modal-footer .btn {
    min-width: 150px;
  }

  .brandDataModal
    .form-group
    .form-body
    .multiUploadWrapper
    .form-group
    div
    label.form-uploader,
  .brandDataModal
    .form-group
    .form-body
    .multiUploadWrapper
    .form-group
    div
    .emptyPhotos,
  .brandDataModal
    .form-group
    .form-body
    .multiUploadWrapper
    .form-group
    div
    .thumbs {
    width: 100%;
  }

  .brandDataModal
    .form-group
    .form-body
    .multiUploadWrapper
    .form-group
    div
    label.form-uploader {
    min-height: 150px;
  }

  .brandDataModal
    .form-group
    .form-body
    .multiUploadWrapper
    .form-group
    div
    .emptyPhotos {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    padding-top: 20px;
  }

  .brandDataModal .modal-content {
    height: auto;
  }

  .brandDataModal .modal-footer .btn {
    min-width: 100px;
  }

  .brandDataModal
    .form-group
    .form-body
    .multiUploadWrapper
    .form-group
    div
    .thumbs {
    margin-top: 50px;
  }
}

@media (max-width: 425px) {
  .images-wrp .img-holder {
    width: 73px;
    min-width: 73px;
  }
}

@media (max-width: 375px) {
  .thumbs .thumb {
    width: 86px;
    height: 86px;
  }
}
